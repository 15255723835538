<template>
  <div>
    <div class="rounded-lg border border-lightGrey-1 mt-8 p-2 md:p-4">
      <div class="flex justify-between items-center">
        <h3 class="text-black font-semibold">Transfer to your wallet</h3>
        <!-- <Button
          text="Copy"
          background="lightRed"
          color="brandRed"
          radius="3xl"
          fontSize="text-sm"
          p="py-1.5 px-4"
          @click="copy(account.accountNumber)"
        /> -->
        <SecondaryButton
          v-show="!balanceLoading"
          text="Copy"
          @click="copy(account.accountNumber)"
          p="py-1.5 px-4"
        />
      </div>
      <div class="grid grid-cols-2 mt-6">
        <p class="text-black">Bank name:</p>
        <Skeleton v-if="balanceLoading" height="1rem" width="8rem" />
        <p v-else class="text-black">{{ account.bankName }}</p>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <p class="text-black">Account name:</p>
        <Skeleton v-if="balanceLoading" height="1rem" width="6rem" />
        <p v-else class="text-black">{{ account.accountName }}</p>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <p class="text-black">Account number:</p>
        <Skeleton v-if="balanceLoading" height="1rem" width="8rem" />
        <p v-else class="text-black">{{ account.accountNumber }}</p>
      </div>
    </div>
    <Button
      :loading="loading"
      :text="liveMode ? 'Done' : 'Close'"
      width="w-full"
      class="mt-12"
      @click="liveMode ? updateWalletBalance() : $emit('close', 'close')"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/SecondaryButton";
import copy from "@/mixins/copy";
export default {
  mixins: [copy],
  components: {
    SecondaryButton,
    Button
  },
  data() {
    return {
      loading: false,
      balanceLoading: false,
      queryTiming: {
        timeout: null,
        timeLeft: 30
      },
      queryCounter: null,
      queryCount: 0,
      oldBalance: 0,
      newBalance: 0
    };
  },
  computed: {
    ...mapState({
      account: state => state?.dashboard?.topupAccount,
      liveMode: state => state?.auth?.liveMode,
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      walletBalance: state =>
        state?.dashboard?.wallet?.settlementWallet?.balance
    })
  },
  mounted() {
    this.getTopupAccount();
  },
  watch: {
    liveMode() {
      this.getMerchantTopupAccount({
        liveMode: this.liveMode
      });
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "getMerchantWallet",
      "getMerchantTopupAccount"
    ]),
    ...mapActions("notification", ["showAlert", "showToast"]),

    getTopupAccount() {
      this.balanceLoading = true;
      this.getMerchantTopupAccount({
        liveMode: this.liveMode
      })
        .then(() => {
          this.balanceLoading = false;
        })
        .catch(() => {
          this.balanceLoading = false;
        });
    },

    updateWalletBalance() {
      this.oldBalance = this.walletBalance;
      // this.newBalance = this.oldBalance;
      this.loading = true;
      this.showToast({
        display: true,
        icon: "success",
        description: "We're verifying your payment. This could take a while."
      });
      this.queryWallet();
    },

    queryWallet() {
      // start the query Timeout
      if (this.queryTiming.timeLeft === 30) {
        this.startQueryTimeout();
      }
      // if the 30secs timeout has passed, end the operation
      if (this.queryTiming.timeLeft <= 0) {
        this.loading = false;
        if (this.newBalance <= this.oldBalance) {
          this.showAlert({
            display: true,
            type: "success",
            description: `Wallet funding not successful. Please wait a while before retrying`
          });
        }
      } else {
        this.getMerchantWallet({
          id: this.merchantOutletId,
          liveMode: this.liveMode
        })
          .then(({ data }) => {
            this.newBalance = data?.settlementWallet.balance;
            // if your balance has changed
            if (data?.settlementWallet?.balance !== this.oldBalance) {
              this.loading = false;
              this.showAlert({
                display: true,
                type: "success",
                description: `Wallet funded successfully`
              });
              this.$emit("close", "done");
            } else {
              // recurse the function
              this.queryCounter = setTimeout(() => {
                this.queryWallet();
              }, 3000);
            }
          })
          .catch(() => {
            this.loading = false;
            if (this.queryTiming.timeLeft > 0) {
              clearInterval(this.queryTiming.timeout);
              this.queryTiming.timeLeft = 30;
            }
          });
      }
    },
    startQueryTimeout() {
      this.queryTiming.timeout = setInterval(() => {
        if (this.queryTiming.timeLeft <= 0) {
          clearInterval(this.queryTiming.timeout);
        }
        this.queryTiming.timeLeft -= 1;
      }, 1000);
    }
  },
  destroyed() {
    clearTimeout(this.queryCounter);
    clearInterval(this.queryTiming.timeout);
  }
};
</script>
